.PictureSectionContainer {
  //   border: 2px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  flex-direction: column;

  > img :first-of-type {
    // border: 2px solid greenyellow;
    position: relative;
    width: 250px;
    height: 140px;
    top: -5%;
  }

  > img:last-of-type {
    // position: absolute;
    // border: 2px solid salmon;
    height: 250px;
    width: 90%;
    margin-bottom: 15%;
  }
}
