.LandingPageContainer {
  min-height: 100vh;
  width: 100vw;

  > div:first-of-type {
    // border: 2px solid green;
    height: 70vh;
    background: linear-gradient(to top, #0c391b, #377440);
    position: relative;

    > img {
      //   border: 2px solid crimson;
      height: 20%;
      width: 30%;
    }

    > div {
      //   border: 2px solid rgb(140, 0, 255);
      position: absolute;
      width: 100%;
      min-height: 128%;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 15%;

      > div:nth-of-type(2) {
        // border: 2px solid crimson;
        margin-top: 7%;
        width: 90%;
        height: 250px;
        background: none;
      }
    }
  }

  > div:last-of-type {
    // border: 2px solid rgb(128, 0, 0);
    height: 30vh;
    position: relative;
    background: rgb(252, 242, 218);
    z-index: -100;
  }
}
