/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.334);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #62ef8a;
  border-radius: 150px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
