$borderRadius: 7px;

.LandingPageDesktopContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  background: #279337;
  background: linear-gradient(to top, #0c391b, #377440);

  > div:first-of-type {
    // border: 2px solid black;
    width: 60%;

    > div:first-of-type {
      //   border: 2px solid red;
      height: 80px;
      margin-top: 5%;
      margin-bottom: 5%;
      position: relative;

      > img {
        height: 130%;
        margin-left: 5%;
        width: 25%;
      }

      > button {
        position: absolute;
        font-weight: 500;
        top: 25%;
        left: 10%;
        background: #f3a942;
        height: 50px;
        width: 25%;
        border-radius: 7px;
        font-size: 20px;
        letter-spacing: 1px;
        box-shadow: 2px 2px 2px 2px rgba(8, 8, 8, 0.384);
      }
    }

    > div:nth-of-type(2) {
      //   border: 2px solid rgb(0, 255, 42);
      position: relative;
      display: flex;
      flex-direction: column;
      height: 70%;

      > div:first-of-type {
        position: absolute;
        // border: 2px solid red;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: white;
        font-weight: 600;
        letter-spacing: 2px;
      }

      > div:nth-of-type(2) {
        // border: 2px solid crimson;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 7%;

        > img:first-of-type {
          height: 70%;
          width: 70%;
        }

        > img:nth-of-type(2) {
          position: absolute;
          top: 0;
        }
      }
    }
  }

  > div:last-of-type {
    // border: 2px solid rgb(149, 255, 0);
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;

    > div:first-of-type {
      width: 80%;
      height: 90%;
      //   border: 2px solid red;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-radius: 7px;
      background: white;
      flex-direction: column;

      > div:first-of-type {
        height: 70px;
        width: 95%;
        display: flex;
        justify-content: space-between;
        margin-top: 5%;

        > img {
          height: 100%;
          width: 20%;
        }

        > div:first-of-type {
          width: 80%;
          display: flex;
          align-items: center;
          font-size: 28px;
          justify-content: center;
          font-weight: 400;
          letter-spacing: 3px;
          margin-right: 7%;
        }
      }

      > div:nth-of-type(2) {
        height: 350px;
        width: 85%;
        display: flex;
        justify-content: space-between;
        margin-top: 5%;
        flex-direction: column;
        font-size: 14px;
        letter-spacing: 1px;
      }

      > div:nth-of-type(3) {
        height: 40px;
        width: 85%;
        display: flex;
        justify-content: space-between;
        margin-top: 5%;
        flex-direction: column;
        align-items: center;
        font-weight: 700;
        letter-spacing: 1px;
      }

      > div:nth-of-type(4) {
        // border: 2px solid rgb(255, 1, 1);
        height: 250px;
        width: 85%;
        display: flex;
        justify-content: center;
        margin-top: 5%;
        flex-direction: column;
        align-items: center;
        border-radius: 7px;
        margin-bottom: 5%;
        background: linear-gradient(to right, #4b0973, #191fb8);
        color: white;
        font-size: 14px;
        letter-spacing: 1px;

        > div:first-of-type {
          width: 80%;
          height: 90%;
          display: flex;
          align-items: center;
          //   border: 2px solid red;
        }
      }
    }
  }
}
