* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@use "styles/_colors.scss";
@use "~nprogress/nprogress";

