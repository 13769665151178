$fontSizePara: 14px;
$fontSizeHeader: 20px;
$fontSizeSlogan: 18px;
$boxShadow: 2px 2px 15px 2px rgba(18, 18, 18, 0.386);

.LandingPageHeroContainer {
  //   border: 2px solid crimson;
  height: 450px;
  width: 90%;
  border-radius: 7px;
  background: linear-gradient(to top, #f5c669, #fff1d4);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  box-shadow: $boxShadow;

  > div:first-of-type {
    border-bottom: 2px solid #ffbc22;
    display: flex;
    justify-content: center;
    width: 80%;

    > img {
      margin-bottom: 5%;
      margin-top: 5%;
    }
  }

  > div:nth-of-type(2) {
    margin-top: 2%;
    font-weight: 700;
    font-size: 20px;
  }

  > div:nth-of-type(3) {
    // border: 2px solid green;
    width: 90%;
    margin-top: 2%;
    font-size: $fontSizePara;
  }

  > div:nth-of-type(4) {
    margin-top: 5%;
    font-weight: 500;
    font-size: 18px;
    width: 80%;
  }

  > div:last-of-type {
    border-radius: 50%;
    height: 10%;
    width: 13%;
    position: absolute;
    cursor: pointer;
    top: 92%;
    right: -2%;
    box-shadow: 2px 2px 7px 2px rgba(18, 18, 18, 0.626);

    > img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }
}
